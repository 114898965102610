<template>
  <BaseBreadcrumb :items="breadcrumbs" />
  <div class="p-d-flex p-ai-center heading">
    <div class="p-mr-1"><h1>Users</h1></div>
    <div>
      <Button
        label="Create"
        icon="pi pi-plus"
        class="p-button-rounded p-button-text p-button-sm"
        @click="$router.push({ name: 'UserCreate' })"
      />
    </div>
  </div>

  <BaseTable
    :data="users"
    :columns="columns"
    @update="goToUpdate"
    @delete="deleteDocument"
    @enable="enableDocument"
  />
  <ConfirmDialog
    :display="displayConfirmDelete"
    :disable="disableConfirmButton"
    @confirm="confirm"
    @display="display"
    :message="'Are you sure you want to disable?'"
  />
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import BaseTable from '@/components/BaseTable'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import { useDeleteDocument } from '@/composables/useDeleteDocument'
import Button from 'primevue/button.js'
import { useToast } from 'primevue/usetoast.js'
import { firestore } from '@/services/firebase'
import { useHandleError } from '@/composables/useHandleError'
import BaseBreadcrumb from '@/components/BaseBreadcrumb.vue'

export default {
  components: { BaseTable, ConfirmDialog, Button, BaseBreadcrumb },
  setup() {
    const users = ref([])
    const toast = useToast()
    const router = useRouter()
    const { handleError } = useHandleError()
    const {
      disableConfirmButton,
      displayConfirmDelete,
      deleteDocument,
      documentId
    } = useDeleteDocument()
    const columns = ref([
      { field: 'email', header: 'Email' },
      { field: 'name', header: 'Name' },
      { field: 'roles', header: 'Roles' },
      { field: 'active', header: 'Active' }
    ])
    const breadcrumbs = [{ label: 'Users' }]

    function getUsers() {
      firestore
        .collection('users')
        .get()
        .then(querySnapshot => {
          users.value = []
          querySnapshot.forEach(doc => {
            const user = doc.data()
            user.id = doc.id
            users.value.push(user)
          })
        })
    }

    function updateDocument(params, msg) {
      firestore
        .collection('users')
        .doc(documentId.value)
        .set(params, { merge: true })
        .then(() => {
          getUsers()
          displayConfirmDelete.value = false
          disableConfirmButton.value = false
          toast.add({
            severity: 'success',
            summary: 'Success Message',
            detail: msg,
            life: 3000
          })
        })
        .catch(error => {
          handleError(error)
        })
    }

    function confirm() {
      disableConfirmButton.value = true
      updateDocument({ active: false }, 'User successfully disabled')
    }

    function display(value) {
      displayConfirmDelete.value = value
    }

    function goToUpdate(id) {
      router.push({
        name: 'UserUpdate',
        params: { id }
      })
    }

    function enableDocument(id) {
      documentId.value = id
      updateDocument({ active: true }, 'User successfully enabled')
    }

    getUsers()

    return {
      users,
      columns,
      displayConfirmDelete,
      disableConfirmButton,
      deleteDocument,
      confirm,
      display,
      goToUpdate,
      enableDocument,
      breadcrumbs
    }
  }
}
</script>

<style lang="scss" scoped>
.heading {
  margin: 0.83rem 0;
  h1 {
    margin: 0;
  }
}
</style>
